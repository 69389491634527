import React from 'react'
import Shieldpic from '../../assets/sheildpic.png';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';
import Blog1 from '../../assets/Gyanecology/blog1.png';
import Blog2 from '../../assets/Gyanecology/blog2.png';
import Blog3 from '../../assets/Gyanecology/blog3.png';
import Gynaecology from '../../assets/Gyanecology/Gynaecology.jpg';
import H1 from '../../assets/hosp1.jpg';
import H2 from '../../assets/hosp2.jpg';
import H3 from '../../assets/hosp3.jpg';
import H4 from '../../assets/hosp4.jpg';
import H5 from '../../assets/hosp5.jpg';
import Madampic from '../../assets/madampic.png';
import M1 from '../../assets/m1.jpg';
import M2 from '../../assets/m2.jpg';
import M3 from '../../assets/m3.jpg';
import M4 from '../../assets/m4.jpg';
import M5 from '../../assets/m5.jpg';
import M6 from '../../assets/m6.jpg';
import M7 from '../../assets/m7.jpg';
import M8 from '../../assets/m8.jpg';

function Aboutclinic() {
  return (
    <div>
        <div>
        <div className='container-fluid p-0 m-0'>
            <div className='hspbanner'>
                <div className='row'>
                    <div className='drdetails'>
                        <div className='container'>
                            <div className="col-auto col-md-10 my-4">
                                    <nav aria-label="breadcrumb" className="second">
                                        <ol className="breadcrumb indigo lighten-6">
                                            <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2 ">Home</span></a><i className="fa fa-angle-double-right text-white" aria-hidden="true" /></li>
                                            <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">About Us</span></a><i className="fa fa-angle-double-right text-uppercase text-white" aria-hidden="true" /></li>
                                            <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2 text-white" href="#"><span>About Dr. G. Sriharsha</span></a></li>
                                        </ol>
                                    </nav>
                            </div>


                            <div className='madamdetails pmsdy-4'>
                                        <h4 class="nameDr blueone">Hormone India Diabetes & Endocrine Clinic</h4>
                                        <p>10 Years Experience Overall</p>

                                        <div className="ratings my-3">
                                          <div className='starrate'>
                                            <i className='fa fa-star starsize box-shadow'></i><span className="fontwave">5</span>
                                          </div>
                                          <div className='mr-4'>
                                            <img src={Shieldpic}/><span> Verified & Most Trusted One</span>
                                          </div>
                                        </div>

                                        {/* <p className='w-50'>Hormone India Diabetes & Endocrine Clinic. is the Scientific Director of Hormone India Diabetes & Endocrine Clinic. He is a well-known Diabetes and Endocrine specialists are medical professionals who specialize in the diagnosis, treatment, and management of disorders related to the Endocrine system, particularly diabetes. The Endocrine system is responsible for producing and secreting hormones, which play a crucial role in regulating various bodily functions such as metabolism, growth and development, and reproductive functions.</p> */}

                                        
                                      </div>


                        </div>
                    </div>
                </div>
            </div>



            <div className='container-fluid px-0'>
              <div className='border-bottom1 '>
                  <div className='container'>

                    <div className='mainTabs'>
                        <a href="#Aboutdr" className='b_anchor inactive'>About</a>
                        <a href="#Photos" className='b_anchor inactive'>Photos</a>
                        <a href="#Reviews" className='b_anchor inactive'>Reviews</a>
                        <a href="#Doctor" className='b_anchor inactive'>Doctor</a>
                    </div>


                    <div class="TabsContent">
                          <div id="Aboutdr">
                          <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                                <div className="et_pb_text_inner"><h1><strong>About Hormone India Diabetes & Endocrine Clinic</strong></h1></div>
                                </div><div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                                <div className="et_pb_text_inner"><p>Diabetes and Endocrine specialists use a variety of diagnostic tools such as blood tests, imaging studies, and hormone tests to determine the underlying cause of a patient's symptoms. They also develop personalized treatment plans based on the patient's individual needs, which may include medication, lifestyle changes, and regular monitoring of blood sugar levels</p>

                                <p>Diabetes and Endocrine specialists are medical professionals who specialize in the diagnosis, treatment, and management of disorders related to the Endocrine system, particularly diabetes. The Endocrine system is responsible for producing and secreting hormones, which play a crucial role in regulating various bodily functions such as metabolism, growth and development, and reproductive functions.</p>

                                <p>Diabetes is a chronic disease that affects the way your body processes blood sugar or glucose. When you have diabetes, your body either doesn't produce enough insulin, the hormone that regulates blood sugar, or it doesn't use it effectively. This can lead to a range of complications such as cardiovascular disease, kidney damage, nerve damage, and eye damage.</p>
                                </div>
                                </div>
                                </div>


                            <div className='contactNow'>
                                <div className='contactTExt'>
                                    <h5>Reach us by <br/>
                                        contacting our 24/7 support team!
                                    </h5>
                                </div>


                                <div className="contact_section d-flex flex-direction-row justify-content-center align-items-center">
                                    <div className="callsection">
                                        <div className="phoneImg">
                                            <CallIcon className="phonewidth"/>
                                        </div>
                                        <div className="callus">
                                            <p className="py-0 my-0 text-left1">Call Us 24/7</p>
                                            <h5 className="py-0 my-0">+91-73373 22732</h5>
                                        </div>
                                    </div>

                                    <div className="bookappointment">
                                        <Button variant="contained" className="radiusBtn">Book Appointment</Button>
                                    </div>
                            </div>
                            </div>
                          </div>

                          <div id="Reviews">
                          <div className='review_bg'>
                                <div className='mt-4 p-4'>
                                 
                                    <div className='gview'>
                                      <h3>Google Reviews</h3>
                                    </div>
                                 
                                      <div className='row'>

                                            <div className='col-md-4'>
                                                <div className='card min335 p-3'>
                                                  <div className='stars'>
                                                    <div className='start_cls'>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    </div>

                                                    <div className='user'>
                                                    <h4>Nikhil Mehtha</h4>
                                                    </div>
                                                  </div>

                                                  <div className='customerimg my-3'>
                                                    
                                                    <p>One of the best Diabetes and Endocrine specialists use a variety of diagnostic tools such as blood tests, imaging studies, and hormone tests to determine the underlying cause of a patient's symptoms. They also develop personalized treatment plans based on the patient's individual needs, which may include medication, lifestyle changes, and regular monitoring of blood sugar levels.</p>
                                                  </div>
                                                </div>


                                            </div>

                                            <div className='col-md-4'>
                                                <div className='card min335 p-3'>
                                                  <div className='stars'>
                                                    <div className='start_cls'>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    </div>

                                                    <div className='user'>
                                                    <h5>Mahe Babu</h5>
                                                    </div>
                                                  </div>

                                                  <div className='customerimg my-3'>
                                                    
                                                    <p>I personally visited Dr G Sriharsha as my father was suffering with diabetes from 10 years but never felt happy on his progress but after visiting Dr Sriharsha my father was perity happy with sugar levels .... So I definitely suggest any one who was having any Hormonally issues most trusted Doctor around the vishakhapatnam.
                                                         </p>
                                                  </div>
                                                </div>


                                            </div>

                                            <div className='col-md-4'>
                                                <div className='card min335 p-3'>
                                                  <div className='stars'>
                                                    <div className='start_cls'>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                                    </div>

                                                    <div className='user'>
                                                    <h5>Mohammed Salam</h5>
                                                    </div>
                                                  </div>

                                                  <div className='customerimg my-3'>
                                                    
                                                    <p>My wife was suffering from a wound cause due to diabetes. Sugar levels were not in control and the wound was not healing from many weeks. My wife was not able to walk and was bed ridden. Dr. Sri Harsha has guided us on the right time by giving the right medicines for controlling diabetes and infection in the leg. He is very friendly and has taken care of us like a family doctor. Thank you Dr. Sri Harsha for the right guidance and proper medicines for my wife's speedy recovery. Now she is able to walk on her own care. I would have no qualms in recommending them to friends and family members.</p>
                                                  </div>
                                                </div>


                                            </div>

                                      </div>
                                </div>
                              </div>
                          </div>

                          <div id="Photos">
                          <div>
                            <div className='mt-4 p-4'>
                                 <div className='gview'>
                                   <h3>Clinic Infrastructure Images</h3>
                                 </div>
                                 <div className="container-fluid">
                                    <div className="row g-0">
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                          <div className="card-body">
                                            <a href={M1} className="galelry-lightbox">
                                            <img src={M1} className="img-fluid rounded"/>
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M2} className="galelry-lightbox">
                                            <img src={M2} className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                          <div className="card-body">
                                          <a href={M3} className="galelry-lightbox">
                                            <img src={M3}  className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M4} className="galelry-lightbox">
                                            <img src={M4}  className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M5} className="galelry-lightbox">
                                            <img src={M5}  className="img-fluid" />
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M6} className="galelry-lightbox">
                                            <img src={M6}  className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M7} className="galelry-lightbox">
                                            <img src={M7}  className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="gallery-item">
                                        <div className="card-body">
                                          <a href={M8} className="galelry-lightbox">
                                            <img src={M8}  className="img-fluid" />
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                          </div>
                          </div>

                          <div id="Doctor">
                          
                                  

                                  <div className='mt-4 bgone'>
                              <h2 className='font-weight-bold px-4 py-2 text-dark'>About Dr.Sriharsha</h2>
                                  <div class="ddam-flex">
                                    <div className='flexdear'>
                                      <div className='madampic'>
                                        <img src={Madampic} alt="" className='madampicsize'/>
                                      </div>

                                      <div className='madamdetails pmsdy-4'>
                                        <h4 class="nameDr">Dr. G. Sriharsha</h4>
                                        <p>Diabetes and Endocrine specialists</p>

                                        <div className="ratings my-3">
                                          <div className='starrate'>
                                            <i className='fa fa-star starsize'></i><span className="fontwave">5</span>
                                          </div>
                                          <div className='mr-4'>
                                            <img src={Shieldpic}/><span className='font-weight-bold'> Verified & Most Trusted One</span>
                                          </div>
                                        </div>

                                        <p><span className='font-weight-bold'>Dr. G. Sriharsha </span>is the Scientific Director of Hormone India Diabetes and Endocrine clinic. Dr.G. Sriharsha specialist are medical professionals who specialize in the diagnosis, treatment, and management of disorders related to the Endocrine system, particularly diabetes. The Endocrine system is responsible for producing and secreting hormones, which play a crucial role in regulating various bodily functions such as metabolism, growth and development, and reproductive functions.</p>

                                        <div className='knowmore my-4'>
                                          <a href="" className='knowmore_cls'>Know more</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                                  </div>

                                  

                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Aboutclinic