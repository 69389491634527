import React from "react";
import YoutubeVideos from "../YoutubeVideos";
function Videos() {
  

  return (
    <div>
      <div className="main-area">
        <div className="container">
          <div className="breadcrums py-1">
            <div className="row pt-4">
              <div className="col-auto col-md-10">
                <nav aria-label="breadcrumb " className="second">
                  <ol className="breadcrumb indigo lighten-6 first">
                    <li className="breadcrumb-item font-weight-bold ">
                      <a className="black-text text-uppercase bread_a" href="#">
                        <span className="mr-md-3 mr-2">Home</span>
                      </a>
                      <i
                        className="fa fa-angle-double-right "
                        aria-hidden="true"
                      />
                    </li>
                    <li className="breadcrumb-item font-weight-bold">
                      <a className="black-text text-uppercase bread_a" href="#">
                        <span className="mr-md-3 mr-2">Patient Guide</span>
                      </a>
                      <i
                        className="fa fa-angle-double-right text-uppercase "
                        aria-hidden="true"
                      />
                    </li>
                    <li className="breadcrumb-item font-weight-bold">
                      <a
                        className="black-text text-uppercase bread_a active-2"
                        href="#"
                      >
                        <span>Reviews & Videos</span>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>

              <h1 className="Title_header">Our Treatment Videos</h1>
              <YoutubeVideos/>

              <div className="container">
        <div className="bookappointment my-4">
          <div class="row">
            <div className="col-md-8">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.1880550856469!2d83.30591162915356!3d17.709161476796798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f9e5671b9b%3A0xe1e1e0fcf8c515f4!2sDr%20G%20Sri%20Harsha%20Endocrinologist%20Diabetologist%2C%20Diabetes%2CThyroid%2CHormone%20Specialist.%20Hormone%20India%20Diabetes%20%26%20Endocrine%20centre!5e0!3m2!1sen!2sin!4v1680949682312!5m2!1sen!2sin"
                className="ifrmae2"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>

            <div className="col-md-4">
              <div className="formSubmit">
                <p>We Are Ready To Help You With A Smile!</p>
                <h4 className="font-weight-bold">
                  <i class="fa fa-cell"></i>Call Us:{" "}
                  <span className="colorora">+91 7337322732</span>
                </h4>

                <form>
                  <input
                    type="text"
                    placeholder="Fullname*"
                    className="form-control mb-3"
                  />

                  <input
                    type="text"
                    placeholder="Mobile*"
                    className="form-control mb-3"
                  />

                  <input
                    type="text"
                    placeholder="Email*"
                    className="form-control mb-3"
                  />

                  <select className="form-control mb-3">
                    <option value="Diabetes">Diabetes</option>
                    <option value="Thyroid">Thyroid</option>
                    <option value="Adrenal">Adrenal</option>
                    <option value="Pituitary">Pituitary</option>
                    <option value="Gonaos">Gonaos</option>
                    <option value="VitaminD & Calcium">
                      VitaminD & Calcium
                    </option>
                    <option value="Growth">Growth</option>
                    <option value="Obesity">Obesity</option>
                  </select>
                  <div className="my-3">
                    <textarea
                      placeholder="Please tell us your conern in detail"
                      className="form-control my-3"
                    ></textarea>
                  </div>
                </form>

                <div className="my-3">
                  <a href="" className="bookapp my-4 w-100">
                    Book Appointment
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default Videos;
