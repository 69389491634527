import React, { useState } from "react";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Aboutdr");
  };

  // contact us
  const [emptyData, setEmptyData] = useState({
    name: "",
    email: "",
    mobile: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        "https://backend.drgsriharsha.com/public/api/v1/contactpage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const addresses = [
    {
      title: "Visakhapatnam",
      points: [
        "Available every day available at Hormone India Diabetes and Endocrine Clinic, Opposite KGH clocktower, Maharanipeta, Visakhapatnam",
        "For appointments",
        "7337322732",
        "7330727373",
      ],
    },
    {
      title: "Srikakulam ",
      points: [
        "Every Sunday 10am to 1pm Tirumala Medical Centre Rama Lakshmana Road, Day & Night area Srikakulam",
        "For appointments",
        "08942278484",
        "8309573369",
      ],
    },
    {
      title: "Vizianagaram",
      points: [
        "Every Sunday 3pm to 5pm, Visakha Doctors Plaza, Opposite Muvva Gopala Hospital, Gumchi Road, Kota junction.",
        "For appointments",
        "08922274243",
        "7997789148",
      ],
    },
    {
      title: "Anakapalle",
      points: [
        "Every Saturday 10 am to 1pm Sri Sagar Health Care RTC Complex Rd, near Varun Bajaj, Sarada Colony",
        "For appointments",
        "8985226060",
        "8500226116",
      ],
    },
    {
      title: "Gajuwaka",
      points: [
        "Every Saturday 2pm to 4pm Padmaja hospital",
        "For appointments",
        "8106759521",
      ],
    },
    {
      title: "Bobbili",
      points: [
        "1st and 3rd Thursdays 10am to 1pm Chaitanya Medical Centre",
        "For appointments",
        "9502717689",
        "9573638008",
      ],
    },
    {
      title: "Narsipatnam",
      points: [
        "2nd and 4th Thursdays 10am to Tom Kumari poly clinic Near Chaitanya School",
        "For appointments",
        "7032488050",
        "9441571463",
      ],
    },
  ];
  const Teluguaddresses = [
    {
      title: "విశాఖపట్నం",
      points: [
        "ప్రతి రోజు అందుబాటులో ఉండును హార్మోన్ ఇండియా డయాబెటిస్ వద్ద మరియు ఎండోక్రైన్ క్లినిక్, KGH CLOCK TOWER ఎదురుగా, మహారాణిపేట విశాఖపట్నం",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "7337322732",
        "7330727373"
      ]
    },
    {
      title: "శ్రీకాకుళం",
      points: [
        "ప్రతి ఆదివారం ఉదయం 10 నుండి మధ్యాహ్నం 1 వరకు తిరుముల మెడికల్ సెంటర్ రామ, లక్ష్మణ్ రోడ్ డే & నైట్ ప్రాంతం శ్రీకాకుళం",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "08942278484",
        "8309573369"
      ]
    },
    {
      title: "విజయనగరం",
      points: [
        "ప్రతి ఆదివారం మధ్యాహ్నం 3 నుండి 5 గంటలు వరకు, విశాఖ డాక్టర్స్ ప్లాజా, మువ్వా గోపాల హాస్పిటల్ ఎదురుగా, గుమ్చి రోడ్, కోట జంక్షన్",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "08922274243",
        "7997789148"
      ]
    },
    {
      title: "అనకాపల్లి",
      points: [
        "ప్రతి శనివారం ఉదయం 10 నుండి మధ్యాహ్నం 1 గంట వరకు, శ్రీ సాగర్ హెల్త్ కేర్, RTC కాంప్లెక్స్ రోడ్, వరుణ్ బజాజ్ సమీపం లో, శారదా కాలనీ",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "8985226060",
        "8500226116"
      ]
    },
    {
      title: "గాజువాక",
      points: [
        "ప్రతి శనివారం మధ్యాహ్నం 2 నుండి 4 గంటల వరకు, పద్మజ హాస్పిటల్",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "8106759521"
      ]
    },
    {
      title: "బొబ్బిలి",
      points: [
        "1 వ మరియు 3 వ గురువారాలు ఉదయం 10 గంటల నుండి మధ్యాహ్నం 1 గంట వరకు, చైతన్య మెడికల్ కేంద్రం",
        "అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "9502717689",
        "9573638008"
      ]
    },
    {
      title: "నర్సీపట్నం",
      points: [
        "2 వ మరియు 4 వ గురువారాలు ఉదయం 10 గంటల నుండి మధ్యాహ్నం 1 గంట వరకు కుమారి పాళీ క్లినిక్ చైతన్య స్కూల్ దగ్గర, అపాయింట్మెంట్ ల కోసం సంప్రదించండి",
        "7032488050",
        "9441571463"
      ]
    }


  ];

  return (
    <div>
      <div id="Drsilpas">
        <div className="container">
          <div className="mt-4 bgone">
            <div className="container">
              <div className="row">
                <h1 className="text-center">Our Locations</h1>
                {addresses.map((address) => {
                  return (
                    <div className="col-md-4 mb-4">
                      <div
                        className="card contact_card"
                        style={{ minHeight: "310px" }}
                      >
                        <div className="mx-5 mt-3">
                          <h4 className="text-center mb-3">{address.title}</h4>
                          {address.points.map((point) => {
                            return (
                              <ul>
                                <li>{point}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row">
              {/* Telugu */}
                {Teluguaddresses.map((address) => {
                  return (
                    <div className="col-md-4 mb-4">
                      <div
                        className="card contact_card1"
                        style={{ minHeight: "310px" }}
                      >
                        <div className="mx-5 mt-3">
                          <h4 className="text-center mb-3">{address.title}</h4>
                          {address.points.map((point) => {
                            return (
                              <ul>
                                <li>{point}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* end Telugu */}
              </div>
            </div>
          </div>

          <div className="bookappointment my-4">
            <div class="row">
              <h2 className="font-weight-bold px-4 py-2 text-dark text-center mb-3">
                Contact us
              </h2>
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.1880550856469!2d83.30591162915356!3d17.709161476796798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f9e5671b9b%3A0xe1e1e0fcf8c515f4!2sDr%20G%20Sri%20Harsha%20Endocrinologist%20Diabetologist%2C%20Diabetes%2CThyroid%2CHormone%20Specialist.%20Hormone%20India%20Diabetes%20%26%20Endocrine%20centre!5e0!3m2!1sen!2sin!4v1680949682312!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-md-4">
                <div className="formSubmit">
                  <p>We Are Ready To Help You With A Smile!</p>
                  <h4 className="font-weight-bold">
                    <i class="fa fa-cell"></i>Call Us:{" "}
                    <span className="colorora">+91 7337322732</span>
                  </h4>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Fullname*"
                      name="fullname"
                      id="fullname"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <input
                      type="text"
                      placeholder="Mobile*"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      id="mobile"
                      name="mobile"
                      className="form-control mb-3"
                    />

                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      id="email"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <select
                      className="form-control mb-3"
                      id="reason"
                      name="reason"
                      value={formData ? formData.reason : ""}
                      onChange={handleChange}
                    >
                      <option>--select problem--</option>
                      <option value="Diabetes">Diabetes</option>
                      <option value="Thyroid">Thyroid</option>
                      <option value="Adrenal">Adrenal</option>
                      <option value="Pituitary">Pituitary</option>
                      <option value="Gonaos">Gonaos</option>
                      <option value="VitaminD & Calcium">
                        VitaminD & Calcium
                      </option>
                      <option value="Growth">Growth</option>
                      <option value="Obesity">Obesity</option>
                    </select>
                    <div className="my-3">
                      <textarea
                        id="message"
                        name="message"
                        value={formData ? formData.name : ""}
                        onChange={handleChange}
                        placeholder="Please tell us your conern in detail"
                        className="form-control my-3"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button className="bookapp my-4 w-100">
                        Book Appointment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
