import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import M1 from "../assets/m1.jpg";
import M2 from "../assets/m2.jpg";
import M3 from "../assets/m3.jpg";
import M4 from "../assets/m4.jpg";
import Madampic from "../assets/madampic.png";
import M5 from "../assets/m5.jpg";
import M6 from "../assets/m6.jpg";
import Shieldpic from "../assets/sheildpic.png";
import M7 from "../assets/m7.jpg";
import M8 from "../assets/m8.jpg";

import Diabetes from "../assets/sri/icons/daibates.png";
import Thyroid from "../assets/sri/icons/Thyroid.png";
import Adrenal_cancer_syndrome from "../assets/sri/Adrenal_cancer_syndrome.png";
import Gonads from "../assets/sri/gonads.jpg";
import Pituitary_tumors from "../assets/sri/icons/Pituitary.jpg";
import Gestational from "../assets/sri/icons/Gestational-Mellitus.png";
import Parathyroid from "../assets/sri/icons/Parathyroid.jpg";
import LipidDisorders from "../assets/sri/icons/Lipid-Disorders.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import BackgroundImage from '../assets/banner/bannerone.jpg';

import loader from "../assets/loader/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Aboutdr");
  };
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    selected_date: "",
    selected_time: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        "https://backend.drgsriharsha.com/public/api/v1/contact",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        handleClose(true);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    console.log(formData);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  // contact us

  const [emptyDatac, setEmptyDatac] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [formDatac, setFormDatac] = useState({});

  const handleSubmitc = (event) => {
    event.preventDefault();

    axios
      .post(
        "https://backend.drgsriharsha.com/public/api/v1/contact_request",
        formDatac,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormDatac(emptyDatac);
        handleClose(true);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChangec = (e) => {
    const { id, value } = e.target;
    setFormDatac({ ...formDatac, [id]: value });
    console.log(formDatac);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div>
      <ToastContainer />
      <div>
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={BackgroundImage} class="d-block w-100" alt="..." />
            
          </div>
        </div>
      </div>


        {/* End Hero */}
        <main id="main">
          {/* ======= Why Us Section ======= */}
          <section id="why-us" className="why-us">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                  <div className="content">
                    <h3>
                      Why choose Hormone India Clinic for diabetes, thyroid and
                      hormonal problems?{" "}
                    </h3>
                    <p>
                      Dr G. Sri Harsha is a well-known name in the field of
                      endocrinology. He is trained in one of the best institutes
                      in India and worked under reputed doctors in the field of
                      endocrinology. He has over 10 years of experience in
                      treating all types of diabetes patients and complex
                      hormonal problems. He has earned many gold medals,
                      delivered lectures across India. He regularly conducted
                      free diabetes and thyroid camps for people of rural
                      background.
                    </p>
                    <p>
                      At Hormone India Clinic, we bring you specialized clinical
                      service for diabetes and endocrine disorders. From
                      prevention and lifestyle management to latest technology
                      for advanced complications, it is one integrated setup.
                      Our qualified team of specialists and counsellors have
                      designed structured programs and packages to suit your
                      individual needs. The premises is equipped with quality
                      lab and pharmacy.
                    </p>
                    <div className="text-center">
                      <a href="#" className="more-btn">
                        Learn More <i className="bx bx-chevron-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 d-flex">
                  <div className="icon-boxes d-flex flex-column justify-content-center">
                    <div className="row">
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-receipt" />
                          <img src={Diabetes} width={90} />
                          <h4 className="yellowfb800d">100000+</h4>
                          <p className="minht">Diabetes Patients</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-cube-alt" />
                          <img src={Thyroid} width={90} />
                          <h4 className="yellowfb800d">1000+</h4>
                          <p className="minht">Thyroid Patients</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-images" />
                          <img src={Pituitary_tumors} width={90} />
                          <h4 className="yellowfb800d">50 +</h4>
                          <p className="minht">Pituitary Patients</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>

                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-images" />
                          <img src={Gestational} width={90} />
                          <h4 className="yellowfb800d">200 +</h4>
                          <p className="minht">Gestational Mellitus Patients</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Why Us Section */}

          <section id="about" className="about section-bg">
            <div className="container">
              <div className="row text-center">
                <h3 className="font-weight-bold">
                  Effective treatment for diabetes and Endocrine
                  <span className="crls"> disorders requires a holistic </span>
                  approach that considers not only the physical symptoms, but
                  also the emotional and psychological well-being of the
                  patient.
                </h3>
                <p className="yrsexp">
                  10+ years of Experience as Diabetes and Endocrine Specialist
                </p>

                <div className="container my-4">
                  <div className="row">
                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <img src={M1} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <img src={M2} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      <img src={M5} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={1000}
                    >
                      <img src={M7} width={200} className="w-100" />
                    </div>
                  </div>
                </div>
              </div>
              <section id="about" className="about section-bg">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-xs-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                      <h3 data-aos="fade-up">About us</h3>
                      <h2 data-aos="fade-up">
                        Few Words About{" "}
                        <span className="blueclr">
                          Hormone India Diabetes & Endocrine Clinic{" "}
                        </span>
                        in Visakhapatnam
                      </h2>
                      <p>
                        At Hormone India Clinic, we bring you specialized
                        clinical service for diabetes and endocrine disorders.
                        From prevention and lifestyle management to latest
                        technology for advanced complications, it is one
                        integrated setup. Our qualified team of specialists and
                        counsellors have designed structured programs and
                        packages to suit your individual needs.
                      </p>
                      <p>
                        Our approach to treat you to facilitate advanced care
                        for patients, and to provide holistic care and
                        treatment, our team includes skilled and experienced
                        endocrinologists, obesity experts, paediatric
                        endocrinologists, and certified diabetes and dite
                        educators. We have management plans for different
                        disorders. Hormone India Endocrinology and Diabetology
                        Clinic strives to provide quality healthcare for
                        patients with diabetes, endocrine and metabolic
                        disorders and obesity.
                      </p>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="icon-box col-md-6" data-aos="fade-up">
                        <div className="icon">
                          <img src={Diabetes} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Diabetes mellitus</a>
                        </h4>
                        <p className="description small">
                          Diabetes mellitus is characterised by an inability to
                          secrete insulin which is needed by the body to convert
                          glucose into energy. The three main types of diabetes
                          – type 1, type 2 and gestational are all defined as
                          metabolic disorders that affect the way the body
                          metabolises or uses digested food to make glucose.{" "}
                        </p>
                      </div>

                      <div className="icon-box col-md-6" data-aos="fade-up">
                        <div className="icon">
                          <img src={Thyroid} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Thyroid disorders</a>
                        </h4>

                        <p className="description small">
                          The thyroid gland is located in the neck just below
                          the Adam’s apple and produces hormones that play a key
                          role in regulating blood pressure, body temperature,
                          heart rate, metabolism and the reaction of the body to
                          other hormones. Condition including hypothyroidism,
                          hyperthyroidism, thyroid adenomas and thyroid cancer
                          can occur due to fluctuation in the amount of hormones
                          released by the thyroid gland.
                        </p>
                      </div>
                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="icon">
                          <img src={Pituitary_tumors} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Pituitary disorders</a>
                        </h4>
                        <p className="description">
                          The pituitary gland is located just off the
                          hypothalamus at the base of the brain. This gland
                          regulates the functions of other glands, as well as
                          growth and several body functions. Overactivity and
                          underactivity of the pituitary gland can cause a range
                          of conditions like acromegaly, empty sella syndrome
                          and hypopituitarism.
                        </p>
                      </div>
                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon">
                          <img src={Parathyroid} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>
                            Metabolic Bone, Calcium and Parathyroid Disorders
                          </a>
                        </h4>
                        <p className="description">
                          The parathyroid glands regulate the amount of calcium
                          and phosphorous in the blood. Metabolic bone disorders
                          are disorders of bone strength such as osteoporosis,
                          osteomalacia, Paget’s disease and parathyroid
                          disorders.{" "}
                        </p>
                      </div>

                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon">
                          <img src={LipidDisorders} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Lipid Disorders</a>
                        </h4>
                        <p className="description">
                          Lipids or lipoproteins are the fats circulating in the
                          bloodstream like low-density lipoproteins (LDL),
                          high-density lipoproteins (HDL) and triglycerides.
                          Lipid disorders can contribute to the formation of
                          plaque build-up in your arteries which is linked to
                          increased risk of heart attack and stroke.{" "}
                        </p>
                      </div>

                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon">
                          <img src={Gestational} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Gonadal and Menstrual Disorders</a>
                        </h4>
                        <p className="description">
                          Males and females have different gonads; testes and
                          ovaries respectively. Gonadal and menstrual disorders
                          stem primarily from hormone dysfunctions, the ovaries
                          and testes produce many of the same hormones, but in
                          different amounts. Menstrual disorders can include
                          abnormally early or late onset of puberty, very light
                          periods, very heavy periods and irregular or absent
                          periods. They can also affect ovulation, including
                          increasing the risk of ovarian cyst development,
                          problems during pregnancy and the early onset of
                          menopause.{" "}
                        </p>
                      </div>

                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <h4 className="title">
                          <a href>See Our Treatment Videos</a>
                        </h4>

                        <div
                          className="video-box d-flex justify-content-center align-items-stretch"
                          data-aos="fade-right"
                        >
                          <a
                            href="https://www.youtube.com/@Dr.GSriharshaEndocrinologist"
                            target="_blank"
                            className="glightbox play-btn mb-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {/* End About Section */}

          <div>
            <>
              {/* ======= Services Section ======= */}
              <section id="services" className="services services">
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>Our specialized clinics</h2>
                    <p>
                      We provide world class comprehensive one stop solution to
                      all hormone related problems. This is your only
                      destination for all diabetes, thyroid, PCOD, growth and
                      all hormone related problems.
                    </p>
                    <p>
                      Our specialised clinics include thyroid, gestational
                      diabetes mellitus, dietician, obesity clinic, pituitary
                      clinics, continuous glucose monitoring systems and insulin
                      pumps.
                    </p>
                  </div>

                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon">
                        <i className="fas fa-heartbeat" />
                      </div>
                      <h4 className="title">
                        <a href="">Diabetes Clinic</a>
                      </h4>
                      <p className="description">
                        The goal of the Diabetes clinic is to encourage patients
                        to receive early specialty care and education – getting
                        them on the right track with their diabetes management
                        before returning them to their primary care physicians
                        for ongoing management. We also provide you with easy
                        access to renowned specialities who are often involved
                        in diabetes care such as cardiologists,
                        ophthalmologists, nephrologists, and dermatologists.{" "}
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <div className="icon">
                        <i className="fas fa-pills" />
                      </div>
                      <h4 className="title">
                        <a href="">Endocrine Surgery</a>
                      </h4>
                      <p className="description">
                        This clinic provides surgical care of thyroid,
                        parathyroid, adrenal, endocrine pancreas as well as
                        neuroendocrine and other tumours metastatic to the
                        liver. Our endocrine surgery team has the unique
                        expertise for complex endocrine problems including
                        patients requiring re-operative surgery or those with
                        complex thyroid cancers and endocrine malignancies.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="icon">
                        <i className="fas fa-notes-medical" />
                      </div>
                      <h4 className="title">
                        <a href="">Obesity Clinic</a>
                      </h4>
                      <p className="description">
                        With sedentary lifestyles and hectic schedules, it is
                        quite common to not take care of what and how much
                        people eat, and hence, a lot of people fall prey to
                        obesity. The clinic has been started to spread awareness
                        and also to treat people suffering from the disorder.{" "}
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon">
                        <i className="fas fa-dna" />
                      </div>
                      <h4 className="title">
                        <a href="">Paediatric Endocrinology Clinic</a>
                      </h4>
                      <p className="description">
                        The clinic takes care of all the hormonal disorders in
                        children such as juvenile diabetes, growth and puberty
                        disorders, and sex differentiation. The clinic is
                        equipped with required technology and expertise to
                        provide comprehensive care to patients.{" "}
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <div className="icon">
                        <i className="fas fa-wheelchair" />
                      </div>
                      <h4 className="title">
                        <a href="">Bone health and osteoporosis clinic</a>
                      </h4>
                      <p className="description">
                        The density of bones can decrease to changes in hormonal
                        changes, especially women, which can lead to fragility
                        fractures, bone deformities and serious disability. The
                        clinic was initiated to ensure patients get treated with
                        great care, and are able to resume their lifestyle
                        without any pain.{" "}
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="icon">
                        <i className="fas fa-notes-medical" />
                      </div>
                      <h4 className="title">
                        <a href="">Thyroid clinic</a>
                      </h4>
                      <p className="description">
                        We have designed our services so that all of the
                        specialists you need including endocrinologists,
                        endocrine surgeons and a complete nursing staff, work
                        together under one roof as a team to accurately diagnose
                        and treat all forms of thyroid disorders. This
                        high-volume centre have experts working hand-in-hand
                        with your primary care physician to help you receive the
                        most seamless care for your condition possible on an
                        ongoing basis.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {/* End Services Section */}
            </>

            {/* ======= Appointment Section ======= */}
            <section id="appointment" className="appointment section-bg">
              <div className="container">
                <div className="section-title">
                  <h2>Make an Appointment</h2>
                  <p>
                    Waiting is painful. Lessen the waiting time with just one
                    phone call. Call us at 7337322732, 7330727373
                  </p>
                </div>
                <ToastContainer />
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <input
                        type="text"
                        name="fullname"
                        className="form-control"
                        id="fullname"
                        value={formData ? formData.fullname : ""}
                        onChange={handleChange}
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>
                    <div className="col-md-4 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={formData ? formData.email : ""}
                        onChange={handleChange}
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div className="validate" />
                    </div>
                    <div className="col-md-4 form-group mt-3 mt-md-0">
                      <input
                        type="number"
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        value={formData ? formData.mobile : ""}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 form-group mt-3">
                      <label id="c_date">Date</label>
                      <input
                        type="date"
                        name="date"
                        min={disablePastDate()}
                        className="form-control datepicker"
                        id="selected_date"
                        value={formData ? formData.selected_date : ""}
                        onChange={handleChange}
                        placeholder="Appointment Date"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>

                    <div className="col-md-4 form-group mt-3">
                      <label id="c_time">Time</label>
                      <input
                        type="time"
                        name="time"
                        className="form-control datepicker"
                        id="selected_time"
                        value={formData ? formData.selected_time : ""}
                        onChange={handleChange}
                        placeholder="Appointment time"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>

                    <div className="col-md-4 form-group mt-3">
                      <select
                        name="department"
                        id="reason"
                        onChange={handleChange}
                        value={formData ? formData.reason : ""}
                        className="form-select"
                      >
                        <option selected>Select Problem</option>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Thyroid">Thyroid</option>
                        <option value="Adrenal">Adrenal</option>
                        <option value="Pituitary">Pituitary</option>
                        <option value="Gonads">Gonads</option>
                        <option value="VitaminD & Calcium">
                          VitaminD & Calcium
                        </option>
                        <option value="Growth">Growth</option>
                        <option value="Obesity">Obesity</option>
                      </select>
                      <div className="validate" />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      id="message"
                      value={formData ? formData.message : ""}
                      onChange={handleChange}
                      placeholder="Message (Optional)"
                      defaultValue={""}
                    />
                    <div className="validate" />
                  </div>
                  <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your appointment request has been sent successfully. Thank
                      you!
                    </div>
                  </div>
                  <div className="text-center">
                    {loading ? (
                      <img src={loader} style={{ height: "100px" }} />
                    ) : (
                      <button type="submit">Make an Appointment</button>
                    )}
                  </div>
                </form>
              </div>
            </section>
            {/* End Appointment Section */}
            {/* ======= Departments Section ======= */}
            <section id="departments" className="departments">
              <div className="container">
                <div className="section-title">
                  <h2>Departments</h2>
                </div>
                <div className="row gy-4">
                  <div className="col-lg-3">
                    <ul className="nav nav-tabs flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link active show"
                          data-bs-toggle="tab"
                          href="#tab-1"
                        >
                          {" "}
                          <i className="fas fa-heartbeat" /> Diabetes mellitus
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-2"
                        >
                          {" "}
                          <i className="fas fa-heart" /> Thyroid disorders
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-3"
                        >
                          <i className="fas fa-plus-square" /> Adrenal glands
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-4"
                        >
                          {" "}
                          <i className="fas fa-heartbeat" /> Pituitary adenoma
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-5"
                        >
                          <i className="fas fa-stethoscope" /> Gonads
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-9">
                    <div className="tab-content">
                      <div className="tab-pane active show" id="tab-1">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Diabetes mellitus</h3>
                            <p class="font-weight-normal">
                              Diabetes and Endocrine disorders are not just
                              medical conditions, but a way of life. It requires
                              constant attention, self-discipline, and a
                              collaborative effort between healthcare
                              professionals and patients. However, with the
                              right treatment and management, those living with
                              diabetes and Endocrine disorders can lead full and
                              fulfilling lives.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Diabetes} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-2">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Thyroid disorders</h3>
                            <p class="font-weight-normal">
                              The thyroid is a small gland located in the neck
                              that produces hormones that regulate metabolism,
                              growth, and development. Thyroid disorders occur
                              when the thyroid produces too much or too little
                              of these hormones. Common thyroid disorders
                              include hypothyroidism, hyperthyroidism, and
                              thyroid nodules. Symptoms of thyroid disorders can
                              vary widely, but may include fatigue, weight gain
                              or loss, difficulty sleeping, and mood changes.
                              Treatment for thyroid disorders may involve
                              medication, surgery, or radioactive iodine
                              therapy, depending on the specific condition and
                              severity of symptoms.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Thyroid} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-3">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Adrenal glands</h3>
                            <p class="font-weight-normal">
                              The adrenal glands are two small glands located on
                              top of the kidneys that produce hormones that
                              regulate various bodily functions, including
                              metabolism, immune system response, and stress
                              management. Adrenal disorders occur when the
                              glands produce too much or too little of these
                              hormones. Common adrenal disorders include adrenal
                              insufficiency, Cushing's syndrome, and adrenal
                              tumors. Symptoms of adrenal disorders can include
                              fatigue, muscle weakness, weight loss or gain, and
                              changes in blood pressure. Treatment for adrenal
                              disorders depends on the specific condition and
                              may involve medication, surgery, or hormone
                              replacement therapy.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src={Adrenal_cancer_syndrome}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-4">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Pituitary adenoma</h3>
                            <p class="font-weight-normal">
                              The pituitary gland is a small gland located at
                              the base of the brain that produces hormones that
                              regulate various bodily functions, including
                              growth, metabolism, and reproduction. Pituitary
                              disorders occur when the gland produces too much
                              or too little of these hormones. Common pituitary
                              disorders include acromegaly, Cushing's disease,
                              and pituitary tumors. Symptoms of pituitary
                              disorders can vary widely, but may include
                              headache, vision changes, fatigue, and menstrual
                              irregularities. Treatment for pituitary disorders
                              depends on the specific condition and may involve
                              medication, surgery, or radiation therapy.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Pituitary_tumors} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-5">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Gonads</h3>
                            <p class="font-weight-normal">
                              The gonads are the reproductive organs responsible
                              for producing and releasing sex hormones and
                              gametes (eggs in females, sperm in males). In
                              females, the gonads are the ovaries, which produce
                              estrogen and progesterone and release eggs during
                              ovulation. In males, the gonads are the testes,
                              which produce testosterone and sperm. Gonadal
                              disorders can affect the production and regulation
                              of sex hormones and gametes, leading to conditions
                              such as infertility, polycystic ovary syndrome
                              (PCOS), and testicular cancer. Treatment for
                              gonadal disorders may involve medication, hormone
                              replacement therapy, or surgery, depending on the
                              specific condition and severity of symptoms.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Gonads} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Departments Section */}
            {/* ======= Doctors Section ======= */}
            <section id="doctors" className="doctors">
              <div className="container">
                <div className="section-title">
                  <h2>OUR DOCTOR</h2>
                  <p>Know the Expert Before You Visit Our Clinic</p>
                </div>
                <div className="row">
                  <div className="mt-4 bgone">
                    <div class="ddam-flex">
                      <div className="flexdear">
                        <div className="madampic">
                          <img
                            src={Madampic}
                            alt="dr.sriharsha"
                            className="madampicsize"
                          />
                        </div>

                        <div className="madamdetails pmsdy-4">
                          <h4 class="nameDr">Dr. G. Sriharsha</h4>
                          <p>Diabetes and Endocrine specialist</p>

                          <div className="ratings my-3">
                            <div className="starrate">
                              <i className="fa fa-star starsize"></i>
                              <span className="fontwave">5</span>
                            </div>
                            <div className="mr-4">
                              <img src={Shieldpic} />
                              <span className="font-weight-bold">
                                {" "}
                                Verified & Most Trusted One
                              </span>
                            </div>
                          </div>

                          <p>
                            <span className="font-weight-bold">
                              Dr. G. Sriharsha{" "}
                            </span>
                            is the Scientific Director of Hormone India Diabetes
                            & Endocrine Clinic. He is a well-known Diabetes and
                            Endocrine specialists are medical professionals who
                            specialize in the diagnosis, treatment, and
                            management of disorders related to the Endocrine
                            system, particularly diabetes. The Endocrine system
                            is responsible for producing and secreting hormones,
                            which play a crucial role in regulating various
                            bodily functions such as metabolism, growth and
                            development, and reproductive functions.
                          </p>

                          <div className="knowmore my-4">
                            <a
                              href=""
                              className="knowmore_cls"
                              onClick={handleClick}
                            >
                              Know more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Doctors Section */}

            {/* ======= Testimonials Section ======= */}

            {/* ======= Gallery Section ======= */}
            <section id="gallery" className="gallery">
              <div className="container">
                <div className="section-title">
                  <h2>Gallery</h2>
                  <p>We are providing wonderful treatment with best quality</p>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row g-0">
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M1} className="img-fluid rounded" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M3} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M4} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M5} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M6} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M7} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href="Gallery" className="galelry-lightbox">
                          <img src={M8} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Gallery Section */}
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
              <div className="container">
                <div className="section-title">
                  <h2>Contact</h2>
                  <p>24/7 you can contact with you're choice</p>
                </div>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.1880550856469!2d83.30591162915356!3d17.709161476796798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f9e5671b9b%3A0xe1e1e0fcf8c515f4!2sDr%20G%20Sri%20Harsha%20Endocrinologist%20Diabetologist%2C%20Diabetes%2CThyroid%2CHormone%20Specialist.%20Hormone%20India%20Diabetes%20%26%20Endocrine%20centre!5e0!3m2!1sen!2sin!4v1680949682312!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="container">
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="info">
                      <div className="address">
                        <i className="fa fa-map-marker" />
                        <h4>Location:</h4>
                        <p>
                          Hormone India Clinic, Accumax Diagostics, Opp
                          <br />
                          KGH Clock Tower Visakha Multi Speciality Clicnics,
                          Sarojini Naidu Tower, Visakhapatnam-530 002
                        </p>
                      </div>
                      <div className="email">
                        <i className="fa fa-envelope" />
                        <h4>Email:</h4>
                        <p>harshagunna@gmail.com</p>
                      </div>
                      <div className="phone">
                        <i className="fa fa-phone" />
                        <h4>Call:</h4>
                        <p>+91 7337322732</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 mt-5 mt-lg-0">
                    <form onSubmit={handleSubmitc} className="php-email-form">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            value={formDatac ? formDatac.name : ""}
                            onChange={handleChangec}
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={formDatac ? formDatac.email : ""}
                            onChange={handleChangec}
                            id="email"
                            placeholder="Your Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={formDatac ? formDatac.subject : ""}
                          onChange={handleChangec}
                          id="subject"
                          placeholder="Subject"
                          required
                        />
                      </div>
                      <div className="form-group mt-3">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          value={formDatac ? formDatac.message : ""}
                          onChange={handleChangec}
                          rows={5}
                          placeholder="Message"
                          required
                          defaultValue={""}
                        />
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message" />
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="text-center">
                        <button className="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            {/* End Contact Section */}
          </div>
        </main>
        {/* End #main */}
      </div>
    </div>
  );
}

export default Home;
