import React from 'react'
import Facebook from '../assets/facebook.png';
import Twitter from '../assets/twitter.png';
import Instagram from '../assets/instagram.png';
import LinkedIn from '../assets/linkedin.png';
import Youtube from '../assets/youtube.png';
import logo from "../assets/ClinicLogo1.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Footer() {
  return (
    <div>
        {/* ======= Footer ======= */}
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 footer-contact">
          <Link to="/"> <img src={logo} height={50}/></Link>
            <p className='text-white'>
            Hormone India Clinic,
              Accumax Diagostics, Opp<br/>
              KGH Clock Tower Visakha Multi Speciality Clinics, Sarojini Naidu Tower, Visakhapatnam-530 002,<br/>
              
              <strong className='text-white'>Phone:</strong> +91 73373 22732<br />
              <strong className='text-white'>Email:</strong> harshagunna@gmail.com<br />
            </p>
          </div>
          <div className="col-lg-4 col-md-6 footer-links">
            <h4 className='text-white'>Useful Links</h4>
            <ul>


              <li><i className="bx bx-chevron-right" /> <a href="/" className='text-white'>Home</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/hypothyroidism" className='text-white'>Hypothyroidism</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/hyperthyroidism" className='text-white'>Hyperthyroidism</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/type1" className='text-white'>Diabetes Mellitus</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/goiter_thyroid" className='text-white'>Goiter</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/male_hypogonadism" className='text-white'>Hypogonadism</a></li>

            </ul>
          </div>
          <div className="col-lg-4 col-md-6 footer-links">
            {/* <h4 className='text-white'>Our Services</h4> */}
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="/page/cancer" className='text-white'>Thyroid Cancer</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/adrenal_insufficiently" className='text-white'>Adrenal Insufficiency</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/PCOD" className='text-white'>PCOS</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/gestational_diabetes" className='text-white'>Gestational Diabetes Mellitus</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/page/hirsutism" className='text-white'>Hirsutism</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="Contact" className='text-white'>Contact</a></li>
            </ul>
          </div>
          {/* <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4 className='text-white'>Join Our Newsletter</h4>
            <p className='text-white'>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action method="post">
              <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
            </form>
          </div> */}
        </div>
      </div>
    </div>
    <div className="container d-lg-flex py-4">
      <div className="me-lg-auto text-center text-lg-start">
        <div className="copyright">
          © Copyright <strong><span>Hormone India Diabetes & Endocrine Clinic</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="#">SRM Software Solutions</a>
        </div>
      </div>
      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="https://www.youtube.com/@Dr.GSriharshaEndocrinologist" target="_blank"><img src={Youtube}/></a>
        <a href="https://www.facebook.com/profile.php?id=100091537093201" target="_blank"><img src={Facebook}/></a>
        <a href="https://www.instagram.com/dr.g_sriharsha/" target="_blank"><img src={Instagram}/></a>
        <a href="https://www.linkedin.com/in/drsriharsha-gunna-817456271/" target="_blank"><img src={LinkedIn}/></a>
      </div>
    </div>
  </footer>{/* End Footer */}
    </div>
  )
}

export default Footer