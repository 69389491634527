import React from "react";
import Shieldpic from "../../assets/sheildpic.png";
import CallIcon from "@mui/icons-material/Call";
import Button from "@mui/material/Button";
import Blog1 from "../../assets/Gyanecology/blog1.png";
import Blog2 from "../../assets/Gyanecology/blog2.png";
import Blog3 from "../../assets/Gyanecology/blog3.png";
import Gynaecology from "../../assets/Gyanecology/Gynaecology.jpg";
import YoutubeVideos from "../YoutubeVideos";

function Aboutdr() {
  return (
    <div>
      <div className="container-fluid p-0 m-0">
        <div className="drbanner">
          <div className="row">
            <div className="drdetails">
              <div className="container">
                <div className="col-auto col-md-12 my-4">
                  <nav aria-label="breadcrumb" className="second">
                    <ol className="breadcrumb indigo lighten-6">
                      <li className="breadcrumb-item font-weight-bold ">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2 ">Home</span>
                        </a>
                        <i
                          className="fa fa-angle-double-right text-white"
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">About Us</span>
                        </a>
                        <i
                          className="fa fa-angle-double-right text-uppercase text-white"
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a active-2 text-white"
                          href="#"
                        >
                          <span>About Dr. G. Sriharsha</span>
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <div className="madamdetails pmsdy-2">
                  <h4 class="nameDr blueone">Dr. G. Sriharsha</h4>
                  <span>Diabetes and endocrine specialist</span>

                  <div className="ratings my-3">
                    <div className="starrate">
                      <i className="fa fa-star starsize box-shadow"></i>
                      <span className="fontwave">5</span>
                    </div>
                    <div className="mr-4">
                      <img src={Shieldpic} />
                      <span> Verified & Most Trusted One</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="border-bottom1 ">
            <div className="container">
              <div className="mainTabs">
                <a href="#Aboutdr" className="b_anchor inactive">
                  About
                </a>
                <a href="#Reviews" className="b_anchor inactive">
                  Reviews
                </a>
                <a href="#Videos" className="b_anchor inactive">
                  Videos
                </a>
              </div>

              <div class="TabsContent">
                <div id="Aboutdr">
                  <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                    <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                      <div className="et_pb_text_inner">
                        <h1>
                          <strong>About Dr. G. Sriharsha</strong>
                        </h1>
                      </div>
                    </div>
                    <div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                      <div className="et_pb_text_inner">
                        <p>
                          Dr G. Sri Harsha is a well-known name in the field of
                          endocrinology. He is trained in one of the best
                          institutes in India and worked under reputed doctors
                          in the field of endocrinology. He has over 10 years of
                          experience in treating all types of diabetes patients
                          and complex hormonal problems. He has earned many gold
                          medals, delivered lectures across India. He regularly
                          conducted free diabetes and thyroid camps for people
                          of rural background
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="contactNow">
                    <div className="contactTExt">
                      <h5>
                        Reach us by <br />
                        contacting our 24/7 support team!
                      </h5>
                    </div>

                    <div className="contact_section d-flex flex-direction-row justify-content-center align-items-center">
                      <div className="callsection">
                        <div className="phoneImg">
                          <CallIcon className="phonewidth" />
                        </div>
                        <div className="callus">
                          <p className="py-0 my-0 text-left1">Call Us 24/7</p>
                          <h5 className="py-0 my-0">+91-73373 22732</h5>
                        </div>
                      </div>

                      <div className="bookappointment">
                        <Button variant="contained" className="radiusBtn">
                          Book Appointment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Reviews">
                  <div id="Reviews" className="review_bg">
                    <div className="mt-4 p-4">
                      <div className="gview">
                        <h3>Google Reviews</h3>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Vilasini Krishna</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                I had approached Dr. Harsha in July this year
                                with a request to provide diabetic treatment to
                                my dad who cannot walk due to old age and is on
                                geriatric support. I stay abroad and not able to
                                take my dad with me due to his health condition.
                                When I requested Dr. Harsha with a request to
                                visit my home to provide treatment my dad , he
                                has been more than considerate and immediately
                                obliged, understanding situation of my dad and
                                mine. His treatment has been really good and my
                                dad’s sugar levels stabilized within 2 monthly
                                cycles. Apart from the same, he has provided
                                advice on few other medical issues to my dad and
                                he got rid of them as well. Dr. Harsha is quite
                                matured, professional and with a strong will to
                                help people. . I am sure he will do great in his
                                career and touch many lives. I strongly
                                recommend him to anyone in need of health
                                treatment related to his area of specialization.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Manojgupta Mamidi</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                Few months back my mother experiences huge
                                variation in her blood sugar
                                levels(Diabetes).one of our family doctor had
                                suggested about Harsha garu, The way Harsha sir
                                treated and the way he keeps the morale was
                                splendid..In few days my mother health condition
                                got better We continued our consultations with
                                Harsha garu ,Now she is completely fine. I can
                                say that Harsha sir is one of the finest doctors
                                available to treat diabetic patients
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Chandra Sekhar</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                So far the best endocrinologist in
                                Visakhapatnam. Have visited many physicians
                                without any results. Within weeks under Dr
                                Harsha's treatment my mother sugar is well
                                controlled. Thank you Sir for your guidance and
                                care. Lakshmi.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Videos">
                  <div className="mt-4 p-4">
                    <div className="gview">
                      <h3>Our Treatment Videos</h3>
                    </div>
                    <YoutubeVideos />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutdr;
