import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import PCod from "../../assets/Gyanecology/PCOD.jpg";
import $ from "jquery";
import Gynaecology from "../../assets/Gyanecology/Gynaecology.jpg";
import Endometriosis from "../../assets/Gyanecology/Endometriosis.jpg";
import Menstrual from "../../assets/Gyanecology/Menstrual-Problems.jpg";
import Blog1 from "../../assets/Gyanecology/blog1.png";
import Blog2 from "../../assets/Gyanecology/blog2.png";
import Blog3 from "../../assets/Gyanecology/blog3.png";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import Cervicalcancer1 from "../../assets/all/Cervical-Cancer.jpg";
import axios from "axios";
import { useState } from "react";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/loader.gif";
import YoutubeVideos from "../YoutubeVideos";

function Dynamic_page() {
  const [isLoading, setIsLoading] = useState(false);
  const [page_data, setPage_data] = useState();

  // submit contact

  const [loadingBook, setLoadingBook] = useState(false);
  const [formData, setFormData] = useState({});

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    reason: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingBook(true);
    axios
      .post(
        "https://backend.drgsriharsha.com/public/api/v1/contactpage",
        formData
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        setLoadingBook(false);
      })
      .catch((err) => {
        setLoadingBook(false);
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // end submit contact

  var url = document.URL;
  const location = useLocation();
  var page_name = url.substr(url.lastIndexOf("/") + 1);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        "https://backend.drgsriharsha.com/public/api/v1/dynamic_pages/" +
          page_name
      )
      .then(function (response) {
        setPage_data(response.data);
        setIsLoading(false);
      });

    $(document).ready(function () {
      $(".b_anchor").click(function () {
        $(".b_anchor").removeClass("b_active").addClass("inactive");
        $(this).removeClass("inactive").addClass("b_active");
      });

      $(".accordion-toggle").on("click", function (event) {
        event.preventDefault();
        // create accordion variables
        var accordion = $(this);
        var accordionContent = accordion.next(".accordion-content");

        // toggle accordion link open class
        accordion.toggleClass("open");
        // toggle accordion content
        accordionContent.slideToggle(250);
      });
    });
  }, [location]);

  if (page_data) {
    return (
      <div>
        <div className="main-area">
          <div className="container">
            <div className="breadcrums py-4">
              <div className="row pt-4">
                <div className="col-auto col-md-10">
                  <nav aria-label="breadcrumb " className="second">
                    <ol className="breadcrumb indigo lighten-6 first">
                      <li className="breadcrumb-item font-weight-bold ">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">Home</span>
                        </a>
                        <i
                          className="fa fa-angle-double-right "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">
                            {page_data.title}
                          </span>
                        </a>
                        <i
                          className="fa fa-angle-double-right text-uppercase "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a active-2"
                          href="#"
                        >
                          <span>{page_data.title}</span>
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <h1 className="Title_header">{page_data.title}</h1>
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="border-bottom1 ">
              <div className="container">
                <div className="mainTabs">
                  <a href="#About" className="b_anchor inactive">
                    About
                  </a>
                  <a href="#Reviews" className="b_anchor inactive">
                    Reviews
                  </a>

                  <a href="#Videos" className="b_anchor inactive">
                    Videos
                  </a>
                  <a href="#QA" className="b_anchor inactive">
                    Q&A's
                  </a>
                  <a href="#Drsilpas" className="b_anchor inactive">
                    Dr. G. Sriharsha
                  </a>
                </div>

                <div class="TabsContent">
                  <div id="About">
                    <div className="mt-4">
                      <div className="diseas_about">
                        <div class="row">
                          <div className="tabs_New">
                            {Parser(page_data.content)}
                            {page_data.images && (
                              <img src={"/" + page_data.images} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Reviews" className="review_bg">
                    <div className="mt-4 p-4">
                      <div className="gview">
                        <h3>Google Reviews</h3>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Adarsh Rudra</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                I have Consulted Dr. G. Sri Harsha sir for my
                                Mother health Firstly on 18-Dec-2021. At the
                                Begining of the Treatment my Mother is suffering
                                from Thyroid, Diabetes and High Blood Pressure.
                                At the Beginning my mother has FBG 403mg/dl.
                                After 6 Weeks her Thyroid Functioning is Normal
                                i.c,29-Jan-2022.After 14weeks today i.c
                                4-Mar-2022 her FBG is 131mg/dl and PPBG is
                                197mg/dl. I feel very thankful to Dr. Harsha sir
                                for bringing my mother's health to normal and
                                she was Happy now.From my experience with Dr.G
                                Harsha Sir i would like to say "Vaidyo Narayana
                                Hari " means Doctor who Treats is the God 🙏🙏🙏
                                Thank you very much Dr.Sri Harsha Sir 🙏😊
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Priyadarshini Sengupta</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                He was there for me when no one was....all of a
                                sudden I was posted to Visakhapatnam..the
                                language was foreign, everything felt new and I
                                was not ready for the change then to top it all
                                off I suddenly had a disease which took me
                                completely by surprise...It was one of the
                                darkest episodes of my life...and in a new city
                                it was really scary for facing something all
                                alone...I got Dr Harsh's no from one of the
                                other doctors I was seeing..I contacted him on a
                                late Saturday night and told my problem...
                                Although everything is closed on Sundays he told
                                me to come see him early morning on the
                                Sunday...from that moment on I knew he was
                                someone I can rely on...he gave me courage and
                                showed the right way...As I am on my way to
                                recovery I look back to that Saturday night and
                                consider it to be God's grace that our paths
                                crossed...if you are suffering from a chronic
                                illness and don't know where to turn to..I
                                definitely recommend Dr Harsha who is not only a
                                great listener but a wonderful, empathetic human
                                being which is so rare to find these days.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Aravind Talluri</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                                Dr. G Sri Harsha, became our family doctor as he
                                has immense knowledge and experience in treating
                                Diabetic patients. He has been giving good
                                medicines for treating my father. He gave apt
                                treatment during critical life threating
                                condition due to diabetics. My father is doing
                                good currently thanks to his guidance and care.
                                I deeply appreciate his services towards
                                patients like my father. I would recommend him
                                as the best doctor in Visakhapatnam.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Videos">
                    <div className="mt-4 p-4">
                      <div className="gview">
                        <h3>Our Treatment Videos</h3>
                      </div>
                    </div>
                    <YoutubeVideos />
                  </div>

                  <div id="Drsilpas">
                    <div className="mt-4 bgone">
                      <h2 className="font-weight-bold px-4 py-2 text-dark">
                        About Dr.Sriharsha
                      </h2>
                      <div class="ddam-flex">
                        <div className="flexdear">
                          <div className="madampic">
                            <img
                              src={Madampic}
                              alt="dr.sriharsha"
                              className="madampicsize"
                            />
                          </div>

                          <div className="madamdetails pmsdy-4">
                            <h4 class="nameDr">Dr. G. Sriharsha</h4>
                            <p>Diabetes and endocrine specialist</p>

                            <div className="ratings my-3">
                              <div className="starrate">
                                <i className="fa fa-star starsize"></i>
                                <span className="fontwave">5</span>
                              </div>
                              <div className="mr-4">
                                <img src={Shieldpic} />
                                <span className="font-weight-bold">
                                  {" "}
                                  Verified & Most Trusted One
                                </span>
                              </div>
                            </div>

                            <p>
                              <span className="font-weight-bold">
                                Dr.G. Sriharsha
                              </span>
                              is a well-known name in the field of
                              endocrinology. He is trained in one of the best
                              institutes in India and worked under reputed
                              doctors in the field of endocrinology. He has over
                              10 years of experience in treating all types of
                              diabetes patients and complex hormonal problems.
                              He has earned many gold medals, delivered lectures
                              across India. He regularly conducted free diabetes
                              and thyroid camps for people of rural background{" "}
                            </p>

                            {/* <div className="knowmore my-4">
                                                            <a href="" className="knowmore_cls">
                                                                Know more
                                                            </a>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bookappointment my-4">
                    <div class="row">
                      <div className="col-md-8">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.1880550856469!2d83.30591162915356!3d17.709161476796798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f9e5671b9b%3A0xe1e1e0fcf8c515f4!2sDr%20G%20Sri%20Harsha%20Endocrinologist%20Diabetologist%2C%20Diabetes%2CThyroid%2CHormone%20Specialist.%20Hormone%20India%20Diabetes%20%26%20Endocrine%20centre!5e0!3m2!1sen!2sin!4v1680949682312!5m2!1sen!2sin"
                          width="750"
                          height="550"
                          style={{ border: "0" }}
                          allowFullScreen
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        />
                      </div>

                      <div className="col-md-4">
                        <div className="formSubmit">
                          <p>We Are Ready To Help You With A Smile!</p>
                          <h4 className="font-weight-bold">
                            <i class="fa fa-cell"></i>Call Us:{" "}
                            <span className="colorora">+91 7337322732</span>
                          </h4>

                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              placeholder="Fullname*"
                              name="fullname"
                              id="fullname"
                              value={formData ? formData.fullname : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="number"
                              placeholder="Mobile*"
                              name="mobile"
                              id="mobile"
                              value={formData ? formData.mobile : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="email"
                              placeholder="Email*"
                              name="email"
                              id="email"
                              value={formData ? formData.email : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <select
                              className="form-control mb-3"
                              name="reason"
                              id="reason"
                              onChange={handleChange}
                              value={formData ? formData.reason : ""}
                            >
                              <option>--Select Reason--</option>

                              <option value="Diabetes">Diabetes</option>
                              <option value="Thyroid">Thyroid</option>
                              <option value="Adrenal">Adrenal</option>
                              <option value="Pituitary">Pituitary</option>
                              <option value="Gonaos">Gonaos</option>
                              <option value="VitaminD & Calcium">
                                VitaminD & Calcium
                              </option>
                              <option value="Growth">Growth</option>
                              <option value="Obesity">Obesity</option>
                            </select>
                            <div className="my-3">
                              <textarea
                                placeholder="Please tell us your concern in detail *"
                                className="form-control my-3"
                                name="message"
                                id="message"
                                value={formData ? formData.message : ""}
                                onChange={handleChange}
                              ></textarea>
                            </div>

                            <div className="gapivvu text-center">
                              {loadingBook ? (
                                <img src={loader} style={{ height: "100px" }} />
                              ) : (
                                <button
                                  type="submit"
                                  className="bookapp  w-100"
                                >
                                  Book Appointment
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <span>Loading</span>;
  }
}
export default Dynamic_page;
