import React from "react";

export default function YoutubeVideos() {
  const videos = [
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/Y7FYc2iN0rY",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/zSctdKw5Q9Y",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/U8nHpLKLqB0",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/ABRJhuRddyw",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/6uIypLP6qEw",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/YfZbFfdFUqQ",
    },
    {
      title: "",
      content: "",
      link: "https://www.youtube.com/embed/yY7gyV-kUG8",
    },
  ];

  return (
    <div className="container px-0">
      <div id="Videos">
        <div className="mt-4 p-4">
          <div className="mt-4">
            <div className="blogs_div">
              <div className="row">
                {videos.map((video, indexKey) => {
                  return (
                    <div className="col-md-4" key={indexKey}>
                      <div className="card mb-4">
                        <div className="video1">
                          <iframe
                            width="100%"
                            height="100%"
                            src={video.link}
                            className="videoframe"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                        {/* <div className="p-2">
                                  <h5>Ultrasound</h5>
                                  <p className='min138'>To get an image (picture) of the uterus, ovaries, and pelvis an ultrasound is done</p>
                                </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
}
