import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import YoutubeVideos from '../YoutubeVideos';



function Reviews() {

  const reviews = [
    {
      'name': 'Vilasini Krishna',
      'review': 'I had approached Dr. Harsha in July this year with a request to provide diabetic treatment to my dad who cannot walk due to old age and is on geriatric support. I stay abroad and not able to take my dad with me due to his health condition. When I requested Dr. Harsha with a request  to visit my home to provide treatment my dad , he has been more than considerate and immediately obliged, understanding situation of my dad and mine. His treatment has been really good and my dad’s sugar levels stabilized within 2 monthly cycles. Apart from the same, he has provided advice on few other medical issues to my dad and he got rid of them as well. Dr. Harsha is quite matured, professional and with a strong will to help people. . I am sure he will do great in his career and touch many lives. I strongly recommend him to anyone in need of health treatment related to his area of specialization.'
    },
    {
      'name': 'Manojgupta Mamidi',
      'review': 'Few months back my mother experiences huge variation in her blood sugar levels(Diabetes).one of our family doctor had suggested about Harsha garu,The way Harsha sir treated and the way he keeps the morale was splendid. In few days my mother health condition got better We continued our consultations with Harsha garu ,Now she is completely fine. I can say that Harsha sir is one of the finest doctors available to treat diabetic patients',
    },
    {
      'name': 'Chandra Sekhar',
      'review': 'So far the best endocrinologist in Visakhapatnam. Have visited many physicians without any results. Within weeks under Dr Harsha treatment my mother sugar is well controlled. Thank you Sir for your guidance and care. Lakshmi.',
    },
    {
      'name': 'Gudivada Murali Krishna',
      'review': 'Heart full thanks to Dr. G Sri Harsha Endocrinologist, HORMONE INDIA, DIABETES and ENDOCRINE CLINIC VISAKHAPATNAM for proper guidance and care. your diet plan really made a big difference to my problem. Definitely recommend to many who are looking for a good (best) endocrinologist.',
    },
    {
      'name': 'Mahe Babu',
      'review': 'I personally visited Dr G Sriharsha as my father was suffering with diabetes from 10 years but never felt happy on his progress but after visiting Dr Sriharsha my father was perity happy with sugar levels .... So I definitely suggest any one who was having any Hormonally issues most trusted Doctor around the vishakhapatnam.',
    },
    {
      'name': 'Sekhar Mallidi',
      'review': 'Dr sriharsha garu is undoubtedly best endocrinolgist in vizag...he spends proper time with patient and explains all aspects of diabetes in simple way....',
    },
    {
      'name': 'Mohammed Salam',
      'review': 'My wife was suffering from a wound cause due to diabetes. Sugar levels were not in control and the wound was not healing from many weeks. My wife was not able to walk and was bed ridden. Dr. Sri Harsha has guided us on the right time by giving the right medicines for controlling diabetes and infection in the leg. He is very friendly and has taken care of us like a family doctor. Thank you Dr. Sri Harsha for the right guidance and proper medicines for my wifes speedy recovery. Now she is able to walk on her own care. I would have no qualms in recommending them to friends and family members.',
    },
    {
      'name': 'Vicky Jalumuru',
      'review': 'Very good experience. Staff are good and polite. Treatment wise we are very satisfied.',
    },
    {
      'name': 'Adarsh Rudra',
      'review': 'I have Consulted Dr. G. Sri Harsha sir for my Mother health Firstly on 18-Dec-2021. At the Begining of the Treatment my Mother is suffering from Thyroid, Diabetes and High Blood Pressure. At the Beginning my mother has FBG 403mg/dl. After 6 Weeks her Thyroid Functioning is Normal i.c,29-Jan-2022.After 14weeks today i.c 4-Mar-2022 her FBG is 131mg/dl and PPBG is 197mg/dl. I feel very thankful to Dr. Harsha sir for bringing my mother health to normal and she was Happy now.From my experience with Dr.G Harsha Sir i would like to say  "Vaidyo Narayana Hari " means Doctor who Treats is the God 🙏🙏🙏Thank you very much Dr.Sri Harsha Sir 🙏😊',
    },
    {
      'name': 'Priyadarshini Sengupta',
      'review': 'He was there for me when no one was....all of a sudden I was posted to Visakhapatnam..the language was foreign, everything felt new and I was not ready for the change then to top it all off I suddenly had a disease which took me completely by surprise...It was one of the darkest episodes of my life...and in a new city it was really scary for facing something all alone...I got Dr Harshs no from one of the other doctors I was seeing..I contacted him on a late Saturday night and told my problem... Although everything is closed on Sundays he told me to come see him early morning on the Sunday...from that moment on I knew he was someone I can rely on...he gave me courage and showed the right way...As I am on my way to recovery I look back to that Saturday night and consider it to be Gods grace that our paths crossed...if you are suffering from a chronic illness and dont know where to turn to..I definitely recommend Dr Harsha who is not only a great listener but a wonderful, empathetic human being which is so rare to find these days',
    },
    {
      'name': 'Dumpala Chinnikrushna',
      'review': 'I think Dr: Sri Harsha is one of the eminent endocrinologist in Visakhapatnam city. He diagnosis very well in serious conditions so I like him, He is cooperative, knowledgeable, skillfull, friendly and wise. He have good ability to solve problems/ Thank you sir',
    },
    {
      'name': 'Aravind Talluri',
      'review': 'Dr. G Sri Harsha, became our family doctor as he has immense knowledge and experience in treating Diabetic patients. He has been giving good medicines for treating my father. He gave apt treatment during critical life threating condition due to diabetics. My father is doing good currently thanks to his guidance and care. I deeply appreciate his services towards patients like my father. I would recommend him as the best doctor in Visakhapatnam.',
    },
    {
      'name': 'Gopala krishna',
      'review': 'Good treatment for thyroid issues for my wife with detailed explanation. Appreciate his services.',
    },
    {
      'name': 'Ugandhar Kothapalli',
      'review': 'Good genuine doctor, good treatment with only minimal investigation. Fully satisfied with his treatment. Thank you Sir.',
    },
    {
      'name': 'Deepthi Nayana',
      'review': 'Best endocrinologist, very good solution of my long standing thyroid problem. Explanation is very good and in simple language. N Deepthi.',
    }
  ]

  

  return (
    <div>


      <div className="main-area">
        <div className="container">
          <div className="breadcrums py-1">
            <div className="row pt-4">
              <div className="col-auto col-md-10">
                <nav aria-label="breadcrumb " className="second">
                  <ol className="breadcrumb indigo lighten-6 first">
                    <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Home</span></a><i className="fa fa-angle-double-right " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Patient Guide</span></a><i className="fa fa-angle-double-right text-uppercase " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2" href="#"><span>Reviews & Videos</span></a></li>
                  </ol>
                </nav>
              </div>

              <h1 className='Title_header'>Patients Reviews & Videos</h1>
            </div>
          </div>
        </div>

        <div className='container-fluid px-0'>

          <div className='container'>

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Reviews">

                <div id="Reviews" className='review_bg'>
                  <div className='mt-4 p-4'>

                    <div className='gview'>
                      <h3>Google Reviews</h3>
                    </div>

                    <div className='row'>

                      {reviews.map((review, index) => {

                        return (
                          <div className='col-md-4 mb-4' key={index}>
                            <div className='card min335 p-3'>
                              <div className='stars'>
                                <div className='start_cls'>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                </div>

                                <div className='user'>
                                  <h5>{review.name}</h5>
                                </div>
                              </div>

                              <div className='customerimg my-3'>

                                <p>{review.review}</p>
                              </div>
                            </div>


                          </div>
                        )
                      })}


                    </div>
                  </div>
                </div>



              </Tab>
              <Tab eventKey="profile" title="Videos">

                <div id="Videos">
                  <div className='mt-4 p-4'>
                    <div className='gview'>
                      <h3>Our Treatment Videos</h3>
                    </div>
                    <YoutubeVideos/>

                  </div>

                </div>



              </Tab>

            </Tabs>



            <div className='bookappointment my-4'>
              <div class="row">
                <div className="col-md-8">
                  <iframe                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.1880550856469!2d83.30591162915356!3d17.709161476796798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f9e5671b9b%3A0xe1e1e0fcf8c515f4!2sDr%20G%20Sri%20Harsha%20Endocrinologist%20Diabetologist%2C%20Diabetes%2CThyroid%2CHormone%20Specialist.%20Hormone%20India%20Diabetes%20%26%20Endocrine%20centre!5e0!3m2!1sen!2sin!4v1680949682312!5m2!1sen!2sin"
 width="750" height="450" style={{ border: "0" }} allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                </div>

                <div className="col-md-4">
                  <div className='formSubmit'>
                    <p>We Are Ready To Help You With A Smile!</p>
                    <h4 className='font-weight-bold'><i class="fa fa-cell"></i>Call Us: <span className='colorora'>+91 7337322732</span></h4>

                    <form>
                      <input type="text" placeholder="Fullname*" className='form-control mb-3' />

                      <input type="text" placeholder="Mobile*" className='form-control mb-3' />

                      <input type="text" placeholder="Email*" className='form-control mb-3' />

                      <select className='form-control mb-3'>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Thyroid">Thyroid</option>
                        <option value="Adrenal">Adrenal</option>
                        <option value="Pituitary">Pituitary</option>
                        <option value="Gonaos">Gonaos</option>
                        <option value="VitaminD & Calcium">VitaminD & Calcium</option>
                        <option value="Growth">Growth</option>
                        <option value="Obesity">Obesity</option>
                      </select>
                      <div className='my-3'>
                        <textarea placeholder='Please tell us your conern in detail' className='form-control my-3'>

                        </textarea>
                      </div>


                    </form>

                    <div className='my-3'>

                      <a href="" className='bookapp my-4 w-100'>Book Appointment</a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>




      </div>
    </div>
  )
}

export default Reviews